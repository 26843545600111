/* styles/color-picker.css */
.color-picker-wrapper {
  @apply mx-auto w-full max-w-md;
}

.rcp {
  @apply flex flex-col rounded-lg border border-gray-200 bg-white shadow-sm;
}

.rcp-body {
  @apply flex flex-col gap-4 p-4;
}

.rcp-section {
  @apply flex flex-col gap-3;
}

.rcp-interactive {
  @apply h-full w-full touch-none select-none;
}

.rcp-saturation {
  @apply relative w-full cursor-move rounded-md border border-gray-200;
  background-image:
    linear-gradient(to bottom, transparent, black),
    linear-gradient(to right, white, transparent);
}

.rcp-saturation-cursor {
  @apply absolute h-5 w-5 rounded-full border-2 border-white;
  @apply -translate-x-[10px] -translate-y-[10px] shadow-[0_0_15px_rgba(0,0,0,0.15)];
}

.rcp-hue {
  @apply relative h-3 w-full cursor-ew-resize rounded-md;
  background-image: linear-gradient(
    to right,
    #ff0000,
    #ffff00,
    #00ff00,
    #00ffff,
    #0000ff,
    #ff00ff,
    #ff0000
  );
}

.rcp-hue-cursor {
  @apply absolute h-5 w-5 rounded-full border-2 border-white;
  @apply -translate-x-[10px] -translate-y-1 shadow-[0_0_15px_rgba(0,0,0,0.15)];
}

.rcp-fields {
  @apply flex flex-col gap-3;
}

.rcp-fields-floor {
  @apply grid grid-cols-1 gap-3 sm:grid-cols-2;
}

.rcp-field {
  @apply flex w-full flex-col;
}

.rcp-field-input {
  @apply w-full bg-transparent text-center text-sm text-gray-700;
  @apply rounded-md border-2 border-gray-200 py-1 outline-none;
  @apply focus:border-blue-500 focus:ring-1 focus:ring-blue-500;
  font-family: inherit;
}

.rcp-field-label {
  @apply text-center text-sm text-gray-600;
}
