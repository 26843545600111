.react-flow__pane.selection {
  cursor:
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAABwlBMVEUAAAAAKwD39/fc3NxVAFX//wAAAADv7+/+/v7t7e2qnKr///8NAADQ0NCampqfn5/7+/vo5ugzMwAAAAAADw+mqKivr68AAAA7OzvKysr29vbb19cAAIAAFRV2c3bk5OQFBQVlZWXj4+Pv7++qnJwAAAAkAAAGBgD7+/sQEBAVFRWPj4/8/Pzq6ugAKysA//8AAAAIAAji4uJFRUUrKyv5+fnX19czADMAAArNzc11dXVaWlrx8fHMzMz/AAANDQCsrq6qqqoLCwtQUFDv7+/l5ecAAAAVFRWFgoXU1NQwMDC6urry8vLi4uK9vb2KiocwKCggAAAAAP8AICAABgD9/f3x8fHDw8OHh4coKCoAAwAEAAAHAAcODgAAVVUIAAjp6ent7e1jY2MAAAMABQUGBgYJAAAAAAAAGhoAMzP/AP8ACQnQ0NBwcHDFxcUABAQJAAkAABFAQECAgIANDQC6uro1NTXz8/MmJikACgoA/wAAEACHiorPz8+jo6MAIAAAABwZGR7////h4eEAAAAAAA+AAAAAMwAAAAfs7OxydXIJCQAzAAAACwCYmJsGBgYVAAAAGgAMAAwREQAAAFUrACsStS2rAAAAlnRSTlMABqQsAwEQwfuPEgEUo///6GQFARF7/////8w/AgxS////+58SAgcr+P///+1vBgEDIdL//9lABRqy//+oIwETh/////9rBQxe/////N2tZCAIAQgs/PHDl2dOPScSAyLd/H5JNSkeEwoFARu3/7k5HA8EAhST/+9KGQEQZP+WCAkz/9g6EQIFJNprHgUXYSgMChYPAwYL4PvuAAABFUlEQVR4AeXSVzPEUBTA8atcbYnubLS1urVWb4tg9U6ii+i9996J3vm+wnhyy7OZPa//30zOSYL+yXh4ennzOvbx9fMPMHFAYJAQHBIaxgThEZFRYBajY2JZIi7eApBgTUxKTqGD1LT0DACwZdqzHNk0kJObl28AgAKxsKiYAkwlpc4fAFBmKa8gV5Eqq6p/gaum1l5XTzynobHpuzY7W1rb2js6u+S/QOnucQFYe/v6BwaH1GFyDW1k1Ay2sfGJyanpmdk58pL5hcUlEJZXVteU9Q2JdsbmlrC9s7vnwJj+pvYPDo/EY/UEMz/H6dn5xeWVzuzo+ub27v6B3ZH0+PT8InMAen17/+B1pH9q3G78Nci95wsvyDN91WX3RgAAAABJRU5ErkJggg==')
      4 4,
    auto !important;
}

.screen-selection {
  cursor:
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAABwlBMVEUAAAAAKwD39/fc3NxVAFX//wAAAADv7+/+/v7t7e2qnKr///8NAADQ0NCampqfn5/7+/vo5ugzMwAAAAAADw+mqKivr68AAAA7OzvKysr29vbb19cAAIAAFRV2c3bk5OQFBQVlZWXj4+Pv7++qnJwAAAAkAAAGBgD7+/sQEBAVFRWPj4/8/Pzq6ugAKysA//8AAAAIAAji4uJFRUUrKyv5+fnX19czADMAAArNzc11dXVaWlrx8fHMzMz/AAANDQCsrq6qqqoLCwtQUFDv7+/l5ecAAAAVFRWFgoXU1NQwMDC6urry8vLi4uK9vb2KiocwKCggAAAAAP8AICAABgD9/f3x8fHDw8OHh4coKCoAAwAEAAAHAAcODgAAVVUIAAjp6ent7e1jY2MAAAMABQUGBgYJAAAAAAAAGhoAMzP/AP8ACQnQ0NBwcHDFxcUABAQJAAkAABFAQECAgIANDQC6uro1NTXz8/MmJikACgoA/wAAEACHiorPz8+jo6MAIAAAABwZGR7////h4eEAAAAAAA+AAAAAMwAAAAfs7OxydXIJCQAzAAAACwCYmJsGBgYVAAAAGgAMAAwREQAAAFUrACsStS2rAAAAlnRSTlMABqQsAwEQwfuPEgEUo///6GQFARF7/////8w/AgxS////+58SAgcr+P///+1vBgEDIdL//9lABRqy//+oIwETh/////9rBQxe/////N2tZCAIAQgs/PHDl2dOPScSAyLd/H5JNSkeEwoFARu3/7k5HA8EAhST/+9KGQEQZP+WCAkz/9g6EQIFJNprHgUXYSgMChYPAwYL4PvuAAABFUlEQVR4AeXSVzPEUBTA8atcbYnubLS1urVWb4tg9U6ii+i9996J3vm+wnhyy7OZPa//30zOSYL+yXh4ennzOvbx9fMPMHFAYJAQHBIaxgThEZFRYBajY2JZIi7eApBgTUxKTqGD1LT0DACwZdqzHNk0kJObl28AgAKxsKiYAkwlpc4fAFBmKa8gV5Eqq6p/gaum1l5XTzynobHpuzY7W1rb2js6u+S/QOnucQFYe/v6BwaH1GFyDW1k1Ay2sfGJyanpmdk58pL5hcUlEJZXVteU9Q2JdsbmlrC9s7vnwJj+pvYPDo/EY/UEMz/H6dn5xeWVzuzo+ub27v6B3ZH0+PT8InMAen17/+B1pH9q3G78Nci95wsvyDN91WX3RgAAAABJRU5ErkJggg==')
      4 4,
    auto !important;
}
