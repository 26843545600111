.why-choose__grid-parent {
  display: grid;
  grid-template-areas:
    'a a b'
    'c d b'
    'c e e';
  grid-template-columns: 1fr 1fr 1fr; /* This defines three equal columns */
  grid-template-rows: 1fr 1fr 1fr;
}

.why-choose__grid-parent > .why-choose__grid-unrestricted {
  grid-area: a;
}

.why-choose__grid-parent > .why-choose__grid-errorhandling {
  grid-area: b;
}

.why-choose__grid-parent > .why-choose__grid-deployment {
  grid-area: c;
}

.why-choose__grid-parent > .why-choose__grid-empty {
  grid-area: d;
}

.why-choose__grid-parent > .why-choose__grid-integrations {
  grid-area: e;
}
